<script lang="ts" setup>
import { GBaseIcon, GTooltip } from '@gem/uikit';
import { computed } from 'vue';
import type { GSidebarTabItemButtonProps } from '../types/index';
import { cn } from '../../helpers/common';

const emit = defineEmits<(e: 'click', event: Event) => void>();
const props = withDefaults(defineProps<GSidebarTabItemButtonProps>(), {
  icon: 'more-setting-20',
  iconViewBox: '0 0 20 20',
  active: false,
  width: '40px',
  highlight: false,
});

const widthStyle = computed(() => {
  if (props.width) {
    return props.width === 'full' ? '100%' : props.width;
  }
  return '';
});

const handleClick = (e: Event) => {
  emit('click', e);
};
</script>

<template>
  <div>
    <GTooltip
      placement="right"
      class="w-full"
      :content-styles="{
        'z-index': 201,
      }"
      :margin-left="-4">
      <div
        :class="
          cn([
            'relative flex aspect-square cursor-pointer items-center justify-center rounded-xl',
            'hover:text-text-dark-500',
            highlight ? 'hover:bg-purple-400' : 'hover:bg-dark-150',
            active ? 'text-text-dark-500' : 'text-text-dark-300',
            active ? (highlight ? 'bg-purple-400' : 'bg-dark-250') : '',
          ])
        "
        :style="{
          width: widthStyle,
        }"
        @click="handleClick">
        <GBaseIcon :name="icon" :view-box="iconViewBox" width="20px" height="20px" />
      </div>
      <template #content>
        <div class="text-text-dark-500 text-12">{{ name }}</div>
      </template>
    </GTooltip>
  </div>
</template>
